import React, { useState, useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5"; // Importing close icon from react-icons

const notifications = [
  {
    message: "Check out our latest products!",
    image: "./assets/img/img_1.jpg",
  },
  {
    message: "Don't miss our special discounts!",
    image: "./assets/img/img_2.jpg",
  },
  {
    message: "Check Out Our Festive collections!",
    image: "./assets/img/img_3.jpg",
  },
  {
    message: "Check Out Our Budget friendly collections!",
    image: "./assets/img/img_4.jpg",
  },
];

const Notification = () => {
  const [currentNotification, setCurrentNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    if (showNotification) {
      const randomIndex = Math.floor(Math.random() * notifications.length);
      setCurrentNotification(notifications[randomIndex]);

      const timeout = setTimeout(() => {
        setShowNotification(false); // Automatically close after 10 seconds
      }, 10000); // 10000 milliseconds = 10 seconds

      return () => clearTimeout(timeout);
    }
  }, [showNotification]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowNotification(true); // Reset showNotification to true every 1 minute
    }, 30000); // Show a new notification every 1 minute

    return () => clearInterval(interval);
  }, []);

  if (!currentNotification || !showNotification) return null;

  const handleClose = () => {
    setShowNotification(false);
  };

  return (
    <div className="notification">
      <img src={currentNotification.image} alt="Notification" />
      <p>{currentNotification.message}</p>
      <IoCloseCircle className="close-icon" onClick={handleClose} />
    </div>
  );
};

export default Notification;
