// import React from "react";

// const Shippingpolicy = () => {
//   return <div></div>;
// };

// export default Shippingpolicy;
import React from "react";

const Shippingpolicy = () => {
  return (
    <div className="shipping-policy container mt-5 mb-5">
      <h1 className="text-center">Shipping Information</h1>
      <p>
        Thank you for choosing our service. This shipping policy outlines our
        dispatch procedures, timelines, and other important information
        regarding your order’s shipment.
      </p>

      <h4>Standard Dispatch Period</h4>
      <h5>Dispatch Timeframe</h5>
      <p>
        Our standard dispatch period is 8-10 business days. This period excludes
        Saturdays, Sundays, and public holidays. Orders placed during weekends
        or public holidays will be processed on the next business day.
      </p>

      <h5>Public Holidays</h5>
      <p>
        We do not work on public holidays. If your order is placed around a
        public holiday, please be aware that it may take longer to process.
      </p>

      <h5>Seasonal Delays and Unforeseen Events</h5>
      <p>
        Due to high volume of orders during seasonal times, festivals, or
        unforeseen events like political unrest, public curfews, or containment
        zones, there may be an additional delay of 7-10 days. If this happens,
        our team will notify you by email or phone to update you on the status
        of your order.
      </p>

      <h4>Communication</h4>
      <h5>Dispatch Notification</h5>
      <p>
        We will notify you via email one day before the expected dispatch date.
        Once your order is dispatched, you will receive a tracking number.
      </p>

      <h4>Potential Delays</h4>
      <h5>Seasonal Delays and Unforeseen Events</h5>
      <p>
        Due to high volume during seasonal times, festivals, or unforeseen
        events like political unrest, public curfews, or containment zones,
        there may be an additional delay of 7-10 days. If this happens, our team
        will notify you by email or phone to update you on the status of your
        order.
      </p>

      <h5>Early Dispatch Requests</h5>
      <p>
        To maintain fairness, we cannot prioritize orders based on individual
        requests. Please avoid requesting early dispatch after placing your
        order, as this could disrupt our processing schedule.
      </p>

      <h4>Shipping Times</h4>
      <h5>Domestic Shipping</h5>
      <p>
        Once your order is dispatched, it generally takes 2-3 working days for
        delivery within India.
      </p>

      <h5>International Shipping</h5>
      <p>
        For international shipments, delivery usually takes 5-8 days after
        dispatch. Please note that these times may vary based on customs
        processes and other international shipping factors.
      </p>

      <h4>Track Your Order</h4>
      <p>
        Tracking information of your order will be available on your orders page
        once we dispatch the order.
      </p>
      <p>My account → Orders → View order → Track your order.</p>
      <p>
        For additional information regarding your order, fill the form on the
        Track Order page.
      </p>

      <h4>Cancellation Policy</h4>
      <p>We cannot cancel the order once the order is placed successfully.</p>

      <h4>Tax and Duties</h4>
      <p>
        Please note that many countries charge varied import duties, customs
        charges, and taxes on shipped items. All such additional charges are to
        be directly borne by the customer upon delivery. Hence, it is advisable
        to check your country’s tax policies before placing an order with us
        because, once the order is placed, we do not offer returns or
        cancellations for international orders.
      </p>
    </div>
  );
};

export default Shippingpolicy;
