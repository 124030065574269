import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  signupUser,
  OtpUser,
  loginUser,
  requestPasswordReset,
  confirmPasswordReset,
} from "./Apiservice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const handleOverlayClick = () => {
    setIsSignUp(!isSignUp);
    setIsOtpVerification(false);
    setIsForgotPassword(false);
    setIsSignedIn(false);
    setIsResetPassword(false);
  };

  const handleForgotPasswordClick = (event) => {
    event.preventDefault();
    setIsForgotPassword(true);
    setIsSignUp(false);
    setIsOtpVerification(false);
    setIsSignedIn(false);
    setIsResetPassword(false);
  };

  const handleBackToSignIn = () => {
    setIsForgotPassword(false);
    setIsSignUp(false);
    setIsOtpVerification(false);
    setIsSignedIn(false);
    setIsResetPassword(false);
  };

  const handleBackToSignUp = () => {
    setIsForgotPassword(false);
    setIsSignUp(true);
    setIsOtpVerification(false);
    setIsSignedIn(false);
    setIsResetPassword(false);
  };

  // --------------------------SIGNUP----------------------
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);
  const [shownewConfirmPassword, setShownewConfirmPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
    setShownewPassword(!shownewPassword);
    setShownewConfirmPassword(!shownewConfirmPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setShownewConfirmPassword(!shownewConfirmPassword);
  };

  const handleSignUpSUBMISSIONClick = async () => {
    if (formData.password !== formData.confirm_password) {
      Swal.fire({
        title: "Error!",
        text: "Passwords do not match",
        icon: "error",
        confirmButtonText: "Try Again",
      });
      return;
    }

    try {
      console.log("Sign up formData:", formData); // Add this line to log form data
      const response = await signupUser(formData);
      Swal.fire({
        title: "Please verifiy OTP!",
        text: "You have successfully registered. Please verifiy OTP.",
        icon: "success",
        confirmButtonText: "OK",
      });
      console.log("Registration successful", response);
      setIsSignUp(false);
      setIsOtpVerification(true);
      setFormData({
        username: "",
        email: "",
        mobile: "",
        password: "",
        confirm_password: "",
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Adjust the status code based on your API
        Swal.fire({
          title: "Error!",
          text: "An account with this email or Phone no already exists.",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Registration failed. Please try again.",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
      console.error("Registration failed", error.response);
    }
  };
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleOtpVerification = async () => {
    try {
      const response = await OtpUser({
        email: email,
        code: otp,
      });
      Swal.fire({
        title: "OTP Verified successfully!",
        text: "You have successfully OTP Verified. Please log in.",
        icon: "success",
        confirmButtonText: "OK",
      });
      console.log("OTP Verified successfully!", response);
      setEmail("");
      setOtp("");
      setIsOtpVerification(false);

      setIsSignedIn(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "OTP Verified failed. Please try again.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
      console.error("Error verifying OTP", error);
    }
  };

  const [emaillogin, setEmaillogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [emailforget, setEmailforget] = useState("");
  const [message, setMessage] = useState("");

  const handleSendResetLinkClick = async () => {
    try {
      const response = await requestPasswordReset(emailforget);
      setMessage("Password reset OTP sent to your email.");
      setIsResetPassword(true);
      setIsForgotPassword(false);
      Swal.fire({
        title: "success!",
        text: "Password reset OTP sent to your email. ",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      setError("Failed to send OTP. Please check the email.");
      Swal.fire({
        title: "Error!",
        text: "Failed to send OTP. Please try again.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  const [emailreset, setEmailreset] = useState("");
  const [otpreset, setOtpreset] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const userData = {
        email: emailreset,
        otp: otpreset,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      const response = await confirmPasswordReset(userData);

      Swal.fire({
        title: "success!",
        text: "Password reset successful. You can now log in ",
        icon: "success",
        confirmButtonText: "OK",
      });
      setEmailreset("");
      setOtpreset("");
      setNewPassword("");
      setConfirmPassword("");
      setIsSignedIn(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to reset password. Please check your details.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
      console.error(
        "Error details: ",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Function to get the CSRF token from cookies
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const handleSignInClick = () => {
    setError(null);

    fetch("https://infiboutique.in/api/api/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Login failed. Please check your credentials.");
      })
      .then((data) => {
        console.log("Login successful:", data);

        // Save user ID to local storage
        localStorage.setItem("userId", data.user.user_id); // Assuming the user ID is stored under user.user_id
        // Optionally save other user info if needed
        localStorage.setItem("username", data.user.username); // Example for saving username

        setEmail("");
        setPassword("");
        setIsSignedIn(true);
        // Redirect to dashboard or another page here if needed
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setError("Login failed. Please check your credentials.");
      });
  };

  return (
    <div className="AuthForm-main">
      <div
        className={`AuthForm-main-AuthForm-container ${
          isSignUp ? "AuthForm-main-right-panel-active" : ""
        }`}
      >
        {!isOtpVerification &&
        !isForgotPassword &&
        !isSignedIn &&
        !isResetPassword ? (
          <>
            {isSignUp && (
              <div className="AuthForm-main-form-container AuthForm-main-sign-up-container">
                <form>
                  <h1>Create Account</h1>

                  <div className="AuthForm-main-infield">
                    <input
                      type="text"
                      placeholder="Name"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                    <label></label>
                  </div>
                  <div className="AuthForm-main-infield">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label></label>
                  </div>
                  <div className="AuthForm-main-infield">
                    <input
                      type="number"
                      placeholder="Mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                    <label></label>
                  </div>
                  <div className="AuthForm-main-infield">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <label></label>
                    <span
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <div className="AuthForm-main-infield">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                    />
                    <label></label>
                    <span
                      onClick={handleToggleConfirmPassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      handleSignUpSUBMISSIONClick();
                      // handleSignUpClick();
                    }}
                  >
                    Sign Up
                  </button>
                </form>
              </div>
            )}

            {/* Sign In Form */}
            {!isSignUp && (
              <div className="AuthForm-main-form-container AuthForm-main-sign-in-container">
                <form>
                  <h1>LOG IN</h1>

                  <div className="AuthForm-main-infield">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label></label>
                  </div>
                  <div className="AuthForm-main-infield">
                    <input
                      type={showPassword ? "password" : "text"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label></label>
                    <span
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <a
                    href="#"
                    className="AuthForm-main-forgot"
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot your password?
                  </a>
                  <button type="button" onClick={handleSignInClick}>
                    LOG IN
                  </button>
                </form>
              </div>
            )}
          </>
        ) : null}

        {isOtpVerification && (
          <div className="AuthForm-main-form-container AuthForm-main-otp-container">
            <form>
              <h1>Verify OTP</h1>
              <div className="AuthForm-main-infield">
                <input
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label></label>
              </div>

              <div className="AuthForm-main-infield">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <label></label>
              </div>

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

              <button type="button" onClick={handleOtpVerification}>
                Verify OTP
              </button>

              <Link to="#" onClick={handleBackToSignUp}>
                Back to Sign Up
              </Link>
            </form>
          </div>
        )}

        {/* Forgot Password Form */}
        {isForgotPassword && !isResetPassword && (
          <div className="AuthForm-main-form-container AuthForm-main-forgot-password-container">
            <form>
              <h1>Reset Password</h1>

              <div className="AuthForm-main-infield">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={emailforget}
                  onChange={(e) => setEmailforget(e.target.value)}
                />
                <label></label>
              </div>

              <button type="button" onClick={handleSendResetLinkClick}>
                Send OTP
              </button>

              <Link to="#" onClick={handleBackToSignIn}>
                Back to Sign In
              </Link>
            </form>
          </div>
        )}

        {/* Reset Password Form */}
        {isResetPassword && (
          <div className="AuthForm-main-form-container AuthForm-main-reset-password-container">
            <form onSubmit={handleResetPasswordSubmit}>
              <h1>Reset Your Password</h1>

              <div className="AuthForm-main-infield">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={emailreset}
                  onChange={(e) => setEmailreset(e.target.value)}
                />
                <label></label>
              </div>
              <div className="AuthForm-main-infield">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otpreset}
                  onChange={(e) => setOtpreset(e.target.value)}
                />
                <label></label>
              </div>
              <div className="AuthForm-main-infield">
                <input
                  // type="password"
                  type={shownewPassword ? "password" : "text"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label></label>
                <span
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="AuthForm-main-infield">
                <input
                  type={shownewConfirmPassword ? "password" : "text"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <label></label>
                <span
                  onClick={handleToggleConfirmPassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <button type="submit">Submit</button>

              <Link to="#" onClick={handleBackToSignIn}>
                Back to Sign In
              </Link>
            </form>
          </div>
        )}

        {/* Sign In Confirmation */}
        {isSignedIn && (
          <div className="AuthForm-main-form-container AuthForm-main-sign-in-confirmation-container">
            <h1>Hi User</h1>
            <p>Your account is successfully signed in</p>
            <p>Do you want to log out?</p>
            <button onClick={handleBackToSignIn}>Log Out</button>
          </div>
        )}

        {/* Overlay */}
        <div className="AuthForm-main-overlay-container">
          <div className="AuthForm-main-overlay">
            <div className="AuthForm-main-overlay-panel AuthForm-main-overlay-left">
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us, please login with your personal info
              </p>
              <button type="button" onClick={handleOverlayClick}>
                Sign In
              </button>
            </div>
            <div className="AuthForm-main-overlay-panel AuthForm-main-overlay-right">
              <h1>Hello, Friend!</h1>
              <p>Enter your personal details and start your journey with us</p>
              <button
                type="button"
                onClick={handleOverlayClick}
                className="pt-3 pt-md-0 pr-3 pr-md-0"
              >
                Sign Up
              </button>
            </div>
          </div>
          <button
            className="AuthForm-main-overlay-button"
            onClick={handleOverlayClick}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
