import React, { useEffect, useState } from "react";
import axios from "axios";
import { deleteCartItem } from "./Apiservice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = localStorage.getItem("userId");

        if (userId) {
          const response = await axios.get(
            `https://infiboutique.in/api/api/cart-fetch/${userId}/`
          );
          setCartItems(response.data.cart);
        } else {
          setError("User ID not found in local storage.");
        }
      } catch (err) {
        console.error(err);
        setError("Failed to fetch cart details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCartDetails();
  }, []);

  const handleRemoveItem = async (cartItemId) => {
    try {
      await deleteCartItem(cartItemId);
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.cart_item_id !== cartItemId)
      );
      Swal.fire({
        icon: "success",
        title: "Removed",
        text: "Item removed successfully from cart!",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error removing item from cart", error);
      setError("Failed to remove item from cart.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to remove item from cart.",
      });
    }
  };

  // const handleSelectItem = (cartItemId) => {
  //   setSelectedItems((prevSelected) => {
  //     if (prevSelected.includes(cartItemId)) {
  //       return prevSelected.filter((id) => id !== cartItemId); // Deselect item
  //     } else {
  //       return [...prevSelected, cartItemId]; // Select item
  //     }
  //   });
  // };

  const handleSelectItem = (cartItemId) => {
    setSelectedItems((prevSelected) => {
      const item = cartItems.find((item) => item.cart_item_id === cartItemId);

      if (item) {
        // Get the selected size's stock details
        const selectedSize = item.selected_options.size;
        const stockDetail = item.stock_details.find(
          (stock) => stock.size === selectedSize
        );

        // Check if the selected size has enough quantity available
        const hasSufficientQuantity =
          stockDetail && stockDetail.quantity >= item.quantity;

        if (hasSufficientQuantity) {
          if (prevSelected.includes(cartItemId)) {
            return prevSelected.filter((id) => id !== cartItemId); // Deselect item
          } else {
            return [...prevSelected, cartItemId]; // Select item
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Insufficient Stock",
            text: `The selected size (${selectedSize}) does not have enough stock.`,
          });
          return prevSelected; // Don't allow selection
        }
      }

      return prevSelected;
    });
  };

  const handleOrderClick = () => {
    const itemsToOrder = cartItems.filter((item) =>
      selectedItems.includes(item.cart_item_id)
    );

    if (itemsToOrder.length > 0) {
      Swal.fire({
        icon: "success",
        title: "Proceeding to Order",
        text: "Redirecting to order page...",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        navigate("/Payment", { state: { items: itemsToOrder } });
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "No items selected",
        text: "Please select at least one item to order.",
      });
    }
  };

  const handleClearCart = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to clear your cart?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, clear it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        const user_id = localStorage.getItem("user_id");
        if (user_id) {
          await axios.delete(
            `https://infiboutique.in/api/api/cart-clear/${user_id}/`
          );
          setCartItems([]);
          Swal.fire({
            icon: "success",
            title: "Cleared",
            text: "Cart cleared successfully!",
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          setError("User ID not found in local storage.");
        }
      } catch (error) {
        console.error("Error clearing cart", error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to clear cart.",
        });
      }
    }
  };

  return (
    <div>
      <h2 className="text-center my-5">Shopping Cart</h2>

      <div className="shopping_cart_area mt-5">
        <div className="container">
          <form action="#">
            <div className="cart_page_inner mb-60">
              <div className="row">
                <div className="col-12">
                  <div className="cart_page_tabel">
                    {loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>{error}</p>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th className="text-center">Information</th>

                            <th>Quantity</th>
                            <th>Total</th>
                            <th>Remove</th>
                            <th className="text-center">Select to order</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.length > 0 ? (
                            [...cartItems]
                              .reverse() // Reverse the array to show the last item first
                              .map((item) => (
                                <tr
                                  key={item.product_id}
                                  className="border-top"
                                >
                                  <td>
                                    <div className="cart_product_thumb">
                                      <img
                                        src={item.images[0]}
                                        alt={item.product_name}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart_product_text">
                                      <h4>{item.product_name}</h4>
                                      <ul>
                                        <li>
                                          <i className="ion-ios-arrow-right"></i>{" "}
                                          Size:{" "}
                                          <span>
                                            {item.selected_options.size}
                                          </span>
                                        </li>
                                        <li>
                                          <i className="ion-ios-arrow-right"></i>{" "}
                                          Style: <span>{item.style}</span>
                                        </li>
                                        <li>
                                          <i className="ion-ios-arrow-right"></i>{" "}
                                          Length:{" "}
                                          <span>
                                            {item.selected_options.length_inch}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                  <td className="product_quantity">
                                    <div className="cart_product_quantity">
                                      <input
                                        min="1"
                                        max="100"
                                        value={item.quantity}
                                        type="number"
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart_product_price">
                                      <span>
                                        ₹
                                        {parseFloat(
                                          item.item_price
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                        })}{" "}
                                        <span style={{ fontSize: "13px" }}>
                                          INR
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="cart_product_remove">
                                      <button
                                        className="btn btn-danger px-4"
                                        type="button"
                                        onClick={() =>
                                          handleRemoveItem(item.cart_item_id)
                                        }
                                      >
                                        Remove Cart
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td>
                                    <div
                                      className="checkboxes__row"
                                      id="cart-orderbox"
                                    >
                                      <div
                                        className="checkboxes__item"
                                        id="cart-orderboxsecond"
                                      >
                                        <label className="checkbox style-h">
                                          <input
                                            type="checkbox"
                                            checked={selectedItems.includes(
                                              item.cart_item_id
                                            )}
                                            onChange={() =>
                                              handleSelectItem(
                                                item.cart_item_id
                                              )
                                            }
                                            disabled={item.sold_out} // Disable if sold out
                                            style={{
                                              opacity: item.sold_out ? 0.5 : 1,
                                              pointerEvents: item.sold_out
                                                ? "none"
                                                : "auto",
                                            }}
                                          />
                                          {!item.sold_out && (
                                            <div className="checkbox__checkmark"></div>
                                          )}
                                          <div className="checkbox__body">
                                            {item.sold_out ? (
                                              <div className="sold_out_message">
                                                Sold Out
                                              </div>
                                            ) : (
                                              <span>Please to Order</span>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </td> */}
                                  <td>
                                    <div
                                      className="checkboxes__row"
                                      id="cart-orderbox"
                                    >
                                      <div
                                        className="checkboxes__item"
                                        id="cart-orderboxsecond"
                                      >
                                        <label className="checkbox style-h">
                                          <input
                                            type="checkbox"
                                            checked={selectedItems.includes(
                                              item.cart_item_id
                                            )}
                                            onChange={() =>
                                              handleSelectItem(
                                                item.cart_item_id
                                              )
                                            }
                                            disabled={
                                              item.sold_out ||
                                              (item.selected_options.size &&
                                                !item.stock_details.some(
                                                  (stock) =>
                                                    stock.size ===
                                                      item.selected_options
                                                        .size &&
                                                    stock.quantity >=
                                                      item.quantity
                                                ))
                                            }
                                            style={{
                                              opacity:
                                                item.sold_out ||
                                                (item.selected_options.size &&
                                                  !item.stock_details.some(
                                                    (stock) =>
                                                      stock.size ===
                                                        item.selected_options
                                                          .size &&
                                                      stock.quantity >=
                                                        item.quantity
                                                  ))
                                                  ? 0.5
                                                  : 1,
                                              pointerEvents:
                                                item.sold_out ||
                                                (item.selected_options.size &&
                                                  !item.stock_details.some(
                                                    (stock) =>
                                                      stock.size ===
                                                        item.selected_options
                                                          .size &&
                                                      stock.quantity >=
                                                        item.quantity
                                                  ))
                                                  ? "none"
                                                  : "auto",
                                            }}
                                          />
                                          {!item.sold_out && (
                                            <div className="checkbox__checkmark"></div>
                                          )}
                                          <div className="checkbox__body">
                                            {item.sold_out ||
                                            (item.selected_options.size &&
                                              !item.stock_details.some(
                                                (stock) =>
                                                  stock.size ===
                                                    item.selected_options
                                                      .size &&
                                                  stock.quantity >=
                                                    item.quantity
                                              )) ? (
                                              <div className="sold_out_message">
                                                Not Enough Stock
                                              </div>
                                            ) : (
                                              <span>Please to Order</span>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="7">Your cart is empty.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="cart_page_button border-top d-flex justify-content-between">
                    <div className="shopping_cart_btn"></div>
                    <div className="shopping_continue_btn">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleOrderClick}
                        // disabled={selectedItems.length === 0}
                      >
                        Place order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Cart;
