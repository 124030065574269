import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Privacypolicy = () => {
  return (
    <div className="container mt-5 mb-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <p>
        At Infiboutique, one of our main priorities is the privacy of our
        visitors. This Privacy Policy document contains types of information
        that is collected and recorded by Infiboutique and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Infiboutique. This policy is not applicable to
        any information collected offline or via channels other than this
        website. Our Privacy Policy was created with the help of the Free
        Privacy Policy Generator.
      </p>

      <h5>Consent</h5>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h5>Information we collect</h5>
      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>

      <h5>How we use your information</h5>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h5>Log Files</h5>
      <p>
        Infiboutique follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services’ analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users’ movement on the website, and gathering demographic
        information.
      </p>

      <h5>Cookies and Web Beacons</h5>
      <p>
        Like any other website, Infiboutique uses ‘cookies’. These cookies are
        used to store information including visitors’ preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users’ experience by customizing our web page
        content based on visitors’ browser type and/or other information.
      </p>

      <h5>Google DoubleClick DART Cookie</h5>
      <p>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.website.com and other sites on the internet. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy.
      </p>

      <h5>Our Advertising Partners</h5>
      <p>
        Some of advertisers on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        has their own Privacy Policy for their policies on user data.
      </p>
      <ul>
        <li>
          Google:{" "}
          <a
            href="https://policies.google.com/technologies/ads"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
      </ul>

      <h5>Third Party Privacy Policies</h5>
      <p>
        Infiboutique’s Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
      </p>

      <h5>CCPA Privacy Rights (Do Not Sell My Personal Information)</h5>
      <p>
        Under the CCPA, among other rights, California consumers have the right
        to:
      </p>
      <ul>
        <li>
          Request that a business that collects a consumer’s personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </li>
        <li>
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </li>
        <li>
          Request that a business that sells a consumer’s personal data, not
          sell the consumer’s personal data.
        </li>
      </ul>

      <h5>GDPR Data Protection Rights</h5>
      <p>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </p>
      <ul>
        <li>
          The right to access – You have the right to request copies of your
          personal data.
        </li>
        <li>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate.
        </li>
        <li>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </li>
        <li>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </li>
        <li>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </li>
        <li>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </li>
      </ul>

      <h5>Children’s Information</h5>
      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p>
        Infiboutique does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>
    </div>
  );
};

export default Privacypolicy;
