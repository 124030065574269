import axios from "axios";

const BASE_URL = "https://infiboutique.in/api/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchFavorites = async (userId) => {
  try {
    const response = await ApiService.get(`fetch-favorites/${userId}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching favorites", error);
    throw error;
  }
};

export const fetchorder = async (userId) => {
  const response = await axios.get(`${BASE_URL}fetch-order/${userId}/`);
  return response.data; // This should return the expected data format
};
export const fetchtrack = async (userId, orderId) => {
  const response = await axios.get(`${BASE_URL}track/${userId}/${orderId}`);
  return response.data;
};

export const createFavorite = async (userId, productId) => {
  const response = await axios.post(`${BASE_URL}create-favorites/`, {
    user_id: userId,
    product_id: productId,
  });
  return response.data;
};

export const removeFavorite = async (favoriteId) => {
  try {
    const response = await ApiService.delete(`remove-favorites/${favoriteId}/`);
    return response.data;
  } catch (error) {
    console.error("Error deleting favorite item", error);
    throw error;
  }
};
// In your api service page
export const fetchSearchResults = async (searchTerm) => {
  try {
    const response = await ApiService.get(`search/?q=${searchTerm}`);
    return response.data; // Adjust based on your API response structure
  } catch (error) {
    console.error("Error during search fetch", error);
    throw error;
  }
};

export const fetchcategorylist = async () => {
  try {
    const response = await ApiService.get("categories/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
// Function to place an order
export const placeOrder = async (orderData) => {
  try {
    const response = await ApiService.post("place-order/", orderData);
    return response.data;
  } catch (error) {
    console.error("Error placing order", error);
    throw error;
  }
};

export const fetchcategoryproductslist = async (categoryid) => {
  try {
    const response = await ApiService.get(`products/${categoryid}/`);
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchproductdetaillist = async (id) => {
  try {
    const response = await ApiService.get(`product-detail/${id}/`);
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
// Add product to cart
export const addToCart = async (cartData) => {
  try {
    const response = await ApiService.post("cart/", cartData);
    return response.data;
  } catch (error) {
    console.error("Error during adding to cart", error);
    throw error;
  }
};

// Corrected deleteCartItem function
export const deleteCartItem = async (cartItemId) => {
  try {
    const response = await ApiService.delete(`cart-remove/${cartItemId}/`);
    return response.data;
  } catch (error) {
    console.error("Error deleting item from cart", error);
    throw error;
  }
};

export const fetchcart = async (userId) => {
  try {
    const response = await ApiService.get(`cart-fetch/?user_id=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching cart details", error);
    throw error;
  }
};

// Register user
export const signupUser = async (userData) => {
  try {
    const response = await ApiService.post("register/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during registration", error);
    throw error;
  }
};

// OtpUser API service function
export const OtpUser = async (userData) => {
  try {
    const response = await ApiService.post("verify-otp/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification", error);
    throw error;
  }
};
// Login user
export const loginUser = async (userData) => {
  try {
    const response = await ApiService.post("login/", userData);
    return response.data;
  } catch (error) {
    console.error("Error during login", error);
    throw error;
  }
};
// Password reset request (send reset link)
export const requestPasswordReset = async (email) => {
  try {
    const response = await ApiService.post("password-reset/", { email });
    return response.data;
  } catch (error) {
    console.error("Error during password reset request", error);
    throw error;
  }
};

// Password reset confirmation (set new password)
export const confirmPasswordReset = async (userData) => {
  try {
    const response = await ApiService.post("password-reset-confirm/", userData);
    return response.data;
  } catch (error) {
    console.error(
      "Error during password reset confirmation",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await ApiService.post("contact/", formData);
    return response.data;
  } catch (error) {
    console.error("Error during contact form submission", error);
    throw error;
  }
};
