import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section class="newsletter_section newsletter_padding border-top">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="newsletter_inner d-flex justify-content-between align-items-center">
                <div class="newsletter_text">
                  <h3>keep connected</h3>
                  <p>Get updates by subscribe our weekly newsletter</p>
                </div>
                <div class="newsletter_subscribe">
                  <form id="mc-form">
                    <input
                      class="border-0"
                      id="mc-email"
                      type="email"
                      autocomplete="off"
                      placeholder="Enter your email address"
                    />
                    <i class="icon-envelope-open icons"></i>
                    <button class="border-0" id="mc-submit">
                      Subscribe
                    </button>
                  </form>

                  <div class="mailchimp-alerts text-centre">
                    <div class="mailchimp-submitting"></div>
                    <div class="mailchimp-success"></div>
                    <div class="mailchimp-error"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer_widgets">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5">
              <div className="footer_widget_list">
                <div className="footer_logo">
                  <Link to="#">
                    <img
                      src="./assets/img/logo/logo.jpg"
                      alt=""
                      style={{ height: "100px" }}
                    />
                  </Link>
                  {/* <p>INFI BOUTIQUE</p> */}
                </div>
                <div className="footer_contact">
                  <div className="footer_contact_list">
                    <span>Our Location</span>
                    <p>Thanjavur, Tamil Nadu.</p>
                  </div>
                  <div className="footer_contact_list">
                    <span>24/7 hotline:</span>
                    <Link
                      to="tel:+91 9894407931
                    "
                    >
                      9894407931
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7">
              <div className="footer_widget_list text-right">
                <div className="footer_menu">
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>

                    <li>
                      <Link to="/Cart">Cart</Link>
                    </li>
                  </ul>
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="/AuthForm">signup/login</Link>
                    </li>
                    <li>
                      <Link to="/TermsAndConditions">Terms&Conditions</Link>
                    </li>
                  </ul>
                  {/* <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="/Privacypolicy">privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/Returnpolicy">Return Policy</Link>
                    </li>

                    <li>
                      <Link to="/Refundpolicy">Refund policy</Link>
                    </li>
                    <li>
                      <Link to="/Shippingpolicy">Shipping Policy</Link>
                    </li>
                  </ul> */}
                </div>
                <div className="footer_social">
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link
                        to="https://twitter.com"
                        data-tippy="Twitter"
                        data-tippy-inertia="true"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-placement="top"
                      >
                        <i className="ion-social-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.facebook.com"
                        data-tippy="Facebook"
                        data-tippy-inertia="true"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-placement="top"
                      >
                        <i className="ion-social-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.google.com"
                        data-tippy="googleplus"
                        data-tippy-inertia="true"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-placement="top"
                      >
                        <i className="ion-social-googleplus-outline"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com"
                        data-tippy="Instagram"
                        data-tippy-inertia="true"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-placement="top"
                      >
                        <i className="ion-social-instagram-outline"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com"
                        data-tippy="Youtube"
                        data-tippy-inertia="true"
                        data-tippy-delay="50"
                        data-tippy-arrow="true"
                        data-tippy-placement="top"
                      >
                        <i className="ion-social-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="copyright_right">
                  <p>
                    ©2024 <span>Infi</span> made with{" "}
                    <i className="ion-heart"></i> by{" "}
                    <Link to="https://hasthemes.com" target="_blank">
                      Brandingworld
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
