import React, { useState } from "react";
import { submitContactForm } from "./Apiservice";
import { Link } from "react-router-dom";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleFocus = (e) => {
    e.target.parentNode.classList.add("focus");
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      e.target.parentNode.classList.remove("focus");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await submitContactForm(form);
      console.log("Form submitted successfully:", response);
      setForm({
        name: "",
        email: "",
        mobile: "",
        address: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting the form", error);
    }
  };

  return (
    <>
      <div className="contact-container">
        <span className="contact-big-circle"></span>
        <img src="img/shape.png" className="contact-square" alt="" />
        <div className="contact-form">
          <div className="contact-contact-info">
            <h3 className="contact-title">Let's get in touch</h3>
            <p className="contact-text">
              At our boutique, we celebrate individuality with a curated
              selection of unique, handcrafted fashion pieces that embody
              elegance. Whether you need a statement piece or everyday
              essentials, our knowledgeable team is ready to help you elevate
              your wardrobe. We’d love to hear from you! Reach out with any
              questions or visit us to explore our collection in person.
            </p>

            <div className="contact-info">
              <div className="contact-information">
                <i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;
                <p>
                  29, Pookara Muslim street, Vandayar Colony, Thanjavur, Tamil
                  Nadu.
                </p>
              </div>
              <div className="contact-information">
                <i className="icon-envelope-letter icons"></i> &nbsp;&nbsp;
                <Link to="mailto:customercare.infi@gmail.com">
                  <p>customercare.infi@gmail.com</p>
                </Link>
              </div>
              <div className="contact-information">
                <i className="icons icon-phone"></i>&nbsp;&nbsp;
                <Link to="tel:+91 9894407931">9894407931</Link>
              </div>
            </div>
            <div className="contact-social-media">
              <p>Connect with us :</p>
              <div className="contact-social-icons">
                <a href="#">
                  <i className="icon-social-facebook icons"></i>
                </a>
                <a href="#">
                  <i className="icon-social-twitter icons"></i>
                </a>
                <a href="#">
                  <i className="icon-social-instagram icons"></i>
                </a>
                <a href="#">
                  <i className="icon-social-youtube icons"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="contact-contact-form">
            <span className="contact-circle contact-one"></span>
            <span className="contact-circle contact-two"></span>
            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="contact-title">Contact us</h3>
              <div className="contact-input-container">
                <input
                  type="text"
                  name="name"
                  className="contact-input"
                  value={form.name}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label htmlFor="name">Username</label>
                <span>Username</span>
              </div>
              <div className="contact-input-container">
                <input
                  type="email"
                  name="email"
                  className="contact-input"
                  value={form.email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label htmlFor="email">Email</label>
                <span>Email</span>
              </div>
              <div className="contact-input-container">
                <input
                  type="tel"
                  name="mobile"
                  className="contact-input"
                  value={form.mobile}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label htmlFor="phone">Phone</label>
                <span>Phone</span>
              </div>
              <div className="contact-input-container contact-textarea">
                <textarea
                  type="text"
                  name="address"
                  className="contact-input"
                  value={form.address}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                ></textarea>
                <label htmlFor="message">address</label>
                <span>address</span>
              </div>
              <div className="contact-input-container contact-textarea">
                <textarea
                  name="message"
                  className="contact-input"
                  value={form.message}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                ></textarea>
                <label htmlFor="message">Message</label>
                <span>Message</span>
              </div>
              <input type="submit" value="Send" className="contact-btn" />
            </form>
          </div>
        </div>
      </div>
      <div className="contact-googlemap">
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.485541909617!2d79.13955637480485!3d10.774075389374543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baab98d94fd9ed3%3A0x2ff6ecc19e5ab57!2sInfi%20Boutique!5e0!3m2!1sen!2sin!4v1728112646429!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;

// import React, { useState } from "react";
// import { submitContactForm } from "./Apiservice"; // Ensure this path is correct

// const Contact = () => {
//   const [form, setForm] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     address: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setForm((prevForm) => ({ ...prevForm, [name]: value }));
//   };

//   const handleFocus = (e) => {
//     e.target.parentNode.classList.add("focus");
//   };

//   const handleBlur = (e) => {
//     if (e.target.value === "") {
//       e.target.parentNode.classList.remove("focus");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await submitContactForm(form);
//       console.log("Form submitted successfully:", response);
//       setForm({
//         name: "",
//         email: "",
//         mobile: "",
//         address: "",
//         message: "",
//       });
//     } catch (error) {
//       console.error("Error submitting the form", error);
//     }
//   };

//   return (
//     <>
//       <div className="contact-container">
//         {/* Contact form content */}
//         <div className="contact-contact-form">
//           <form onSubmit={handleSubmit} autoComplete="off">
//             <h3 className="contact-title">Contact us</h3>
//             <div className="contact-input-container">
//               <input
//                 type="text"
//                 name="name"
//                 className="contact-input"
//                 value={form.name}
//                 onChange={handleChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               />
//               <label htmlFor="name">Username</label>
//               <span>Username</span>
//             </div>
//             <div className="contact-input-container">
//               <input
//                 type="email"
//                 name="email"
//                 className="contact-input"
//                 value={form.email}
//                 onChange={handleChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               />
//               <label htmlFor="email">Email</label>
//               <span>Email</span>
//             </div>
//             <div className="contact-input-container">
//               <input
//                 type="tel"
//                 name="mobile"
//                 className="contact-input"
//                 value={form.mobile}
//                 onChange={handleChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               />
//               <label htmlFor="mobile">Phone</label>
//               <span>Phone</span>
//             </div>
//             <div className="contact-input-container">
//               <input
//                 type="text"
//                 name="address"
//                 className="contact-input"
//                 value={form.address}
//                 onChange={handleChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               />
//               <label htmlFor="address">Address</label>
//               <span>Address</span>
//             </div>
//             <div className="contact-input-container contact-textarea">
//               <textarea
//                 name="message"
//                 className="contact-input"
//                 value={form.message}
//                 onChange={handleChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               ></textarea>
//               <label htmlFor="message">Message</label>
//               <span>Message</span>
//             </div>
//             <input type="submit" value="Send" className="contact-btn" />
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Contact;
