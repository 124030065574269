import React, { useEffect, useState } from "react";
import { fetchproductdetaillist, createFavorite } from "./Apiservice";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { HiArrowNarrowRight } from "react-icons/hi";
import axios from "axios";
import Slider from "react-slick";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "bootstrap";
Modal.setAppElement("#root");

const ProductDetail = () => {
  const { id } = useParams();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hoveredImages, setHoveredImages] = useState({});

  const [showLengthInput, setShowLengthInput] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    size: "",
    zipper: "",
    sleeve: "",
    length: "",
    pockets: "",
    length_inch: "",
  });
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const data = await fetchproductdetaillist(id);
        setProductDetail(data);
        if (data.images && data.images.length > 0) {
          setMainImage(data.images[0].image);
        }
        if (data.related_products) {
          setRelatedProducts(data.related_products);
        }
      } catch (err) {
        console.error("Error fetching product details:", err);
      }
    };

    getProductDetails();
  }, [id]);
  const validateSelections = () => {
    return selectedOptions.size !== ""; // Only check if size is selected
  };

  const csrfToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["X-CSRFToken"] = csrfToken;

  const handleAddToCart = async () => {
    const userId = localStorage.getItem("userId");

    // 1. Check if the user is logged in
    if (!userId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "User is not logged in. Please log in to continue.",
      });
      return;
    }

    // 2. Check if size is selected
    if (!validateSelections()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select size",
      });
      return;
    }

    // 3. Get the selected size and quantity
    const selectedSize = selectedOptions.size;
    const selectedQuantity = quantity;

    // 4. Find the available stock for the selected size
    const stockInfo = productDetail.stock_details.find(
      (stock) => stock.size === selectedSize
    );

    // 5. Validate that the selected quantity does not exceed available stock
    if (stockInfo && selectedQuantity > stockInfo.quantity) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Only ${stockInfo.quantity} items are available for size ${selectedSize}. Please add fewer items.`,
      });
      return;
    }

    // 6. Calculate the total price
    const totalPrice = calculateTotalPrice();

    // 7. Create cart data
    const cartData = {
      user_id: userId,
      product_id: productDetail.id,
      size: selectedSize,
      zipper: selectedOptions.zipper,
      sleeve: selectedOptions.sleeve,
      length: selectedOptions.length,
      lining: selectedOptions.lining,
      pockets: selectedOptions.pockets,
      length_inch: selectedOptions.length_inch,
      quantity: selectedQuantity,
      item_price: totalPrice,
    };

    try {
      const response = await fetch("https://infiboutique.in/api/api/cart/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(cartData),
      });

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Add Cart",
          text: "Product added to cart successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message || "Failed to add product to cart.",
        });
      }
    } catch (err) {
      console.error("Error adding product to cart:", err);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error adding product to cart.",
      });
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = () => {
    setSelectedProduct(productDetail);
    setModalIsOpen(true);
  };
  const handleThumbnailClick = (src) => {
    setMainImage(src);
    setSelectedProduct(productDetail);
  };

  const pricingStructure = {
    size: {
      XS: 0,
      S: 0,
      M: 0,
      L: 0,
      XL: 0,
      XXL: 0,
      XXXL: 150,
    },
    zipper: { CENTER: 50, Twoside: 100 },
    sleeve: { NormalElbowSleeve: 0, SemiPuff: 0 },
    length: { BELOW_47Inch: 0, ABOVE_47Inch: 150 },
    pockets: { ONE_SIDE: 0, DOUBLE_SIDE: 50 },
    liningOption: { Lining: 200 },
  };

  const calculateTotalPrice = () => {
    if (!productDetail) return 0; // Ensure productDetail is loaded

    const basePrice = Number(calculateTotalDiscountPrice()); // Ensure it's a number
    let totalAdditionalCost = 0;

    totalAdditionalCost += pricingStructure.size[selectedOptions.size] || 0;
    totalAdditionalCost += pricingStructure.zipper[selectedOptions.zipper] || 0;
    totalAdditionalCost += pricingStructure.sleeve[selectedOptions.sleeve] || 0;
    totalAdditionalCost += pricingStructure.length[selectedOptions.length] || 0;
    totalAdditionalCost +=
      pricingStructure.liningOption[selectedOptions.lining] || 0;
    totalAdditionalCost +=
      pricingStructure.pockets[selectedOptions.pockets] || 0;

    console.log("Base Price:", basePrice);
    console.log("Total Additional Cost:", totalAdditionalCost);

    // Multiply the additional costs by quantity
    const totalAdditionalCostWithQuantity = totalAdditionalCost * quantity;

    // Add the base price and the additional costs with quantity
    const totalPrice = basePrice + totalAdditionalCostWithQuantity;

    return Math.round(totalPrice); // Return the final price
  };

  // const calculateTotalPrice = () => {
  //   if (!productDetail) return 0;

  //   const basePrice = Number(calculateTotalDiscountPrice());
  //   let totalAdditionalCost = 0;

  //   totalAdditionalCost += pricingStructure.size[selectedOptions.size] || 0;
  //   totalAdditionalCost += pricingStructure.zipper[selectedOptions.zipper] || 0;
  //   totalAdditionalCost += pricingStructure.sleeve[selectedOptions.sleeve] || 0;
  //   totalAdditionalCost += pricingStructure.length[selectedOptions.length] || 0;
  //   totalAdditionalCost +=
  //     pricingStructure.liningOption[selectedOptions.lining] || 0;
  //   totalAdditionalCost +=
  //     pricingStructure.pockets[selectedOptions.pockets] || 0;

  //   console.log("Base Price:", basePrice);
  //   console.log("Total Additional Cost:", totalAdditionalCost);

  //   const totalPrice = (basePrice + totalAdditionalCost) * quantity;

  //   return Math.round(totalPrice);
  // };

  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % productDetail.images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + productDetail.images.length) %
        productDetail.images.length
    );
  };

  const settingscard = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };

  const calculateTotalDiscountPrice = () => {
    const originalPrice = parseFloat(productDetail.price) || 0; // Ensure it's a number
    const discountPercentage = productDetail.discount_percentage || 0; // Fallback to 0 if undefined
    const discount = (originalPrice * discountPercentage) / 100; // Calculate discount amount
    const discountedPrice = originalPrice - discount; // Calculate discounted price
    const totalPrice = discountedPrice * quantity; // Calculate total price based on quantity

    return totalPrice.toFixed(2); // Return formatted price as string
  };

  const [favorites, setFavorites] = useState([]);

  const toggleFavorite = (productId) => {
    if (favorites.includes(productId)) {
      setFavorites(favorites.filter((id) => id !== productId)); // Remove from favorites
    } else {
      setFavorites([...favorites, productId]); // Add to favorites
    }
  };
  const handleAddToFavorites = async (productId) => {
    const userId = localStorage.getItem("userId"); // Get userId from local storage
    if (userId) {
      try {
        await createFavorite(userId, productId);

        Swal.fire({
          icon: "success",
          title: "Add Cart",
          text: "Product added to favorites successfully",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add favorites.",
        });
        console.error("Error adding to favorites:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to add favorites.",
      });
    }
  };
  const [showsizeModal, setShowsizeModal] = useState(false);

  // Function to open and close the modal
  const togglesizeModal = () => setShowsizeModal(!showsizeModal);

  return (
    <div>
      <h2 className="text-center my-5">PRODUCT DETAIL</h2>
      <section className="product_details mb-135">
        <div className="container">
          {productDetail ? (
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="product_zoom_gallery ">
                  <div className="zoom_gallery_inner ">
                    <div className="product_zoom_main_img">
                      <div className="product_zoom_thumb">
                        <img
                          src={mainImage}
                          alt={productDetail.name}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="productdetailimagefullview-icon"
                        onClick={handleOpenModal}
                      >
                        <i className="icon-size-fullscreen icons"></i>
                      </div>
                    </div>
                    <div className="zoom_tab_img ">
                      {productDetail.images &&
                        productDetail.images.map((thumbnail, index) => (
                          <Link
                            key={index}
                            className="zoom_tabimg_list"
                            to="#"
                            onClick={() =>
                              handleThumbnailClick(thumbnail.image)
                            }
                          >
                            <img
                              src={thumbnail.image}
                              alt="tab-thumb"
                              className=""
                            />
                          </Link>
                        ))}
                      {productDetail.discount_percentage > 0 && (
                        <div className="product_label">
                          <span>-{productDetail.discount_percentage}%</span>
                        </div>
                      )}
                      {productDetail.sold_out && (
                        <div className="product_label sold_out" id="sold">
                          <span>Sold Out</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="product_d_right">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <h1>{productDetail.name}</h1>
                    <div className="price_box">
                      <span className="current_price">
                        ₹
                        {calculateTotalDiscountPrice().toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        <span style={{ fontSize: "18px" }}>INR</span>
                      </span>
                      {productDetail.discount_percentage > 0 && (
                        <span
                          className="old_price"
                          style={{ marginLeft: "10px" }}
                        >
                          ₹
                          {parseFloat(productDetail.price).toLocaleString(
                            "en-IN",
                            { minimumFractionDigits: 2 }
                          )}{" "}
                          <span style={{ fontSize: "13px" }}>INR</span>
                        </span>
                      )}
                    </div>

                    <div className="product_desc">
                      <p>{productDetail.description}</p>
                    </div>

                    <div className="filter__list widget_size ">
                      <h3> fabric </h3>
                      <p className="options-list mt-3">
                        {productDetail.fabric}
                      </p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>style </h3>
                      <p className="options-list mt-3">{productDetail.style}</p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>Model wearing height </h3>
                      <p className="options-list mt-3">
                        {productDetail.model_height}
                      </p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>Model wearing size </h3>
                      <p className="options-list mt-3">
                        {productDetail.model_wearing_size}
                      </p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3> Size Chart </h3>
                      <button
                        className="view-size-chart-button options-list py-2"
                        onClick={togglesizeModal}
                      >
                        View Size Chart
                      </button>
                    </div>

                    {[
                      "Size",
                      "feeding Zipper",
                      "Sleeve Type",
                      "lining",
                      "Length",
                    ]
                      .filter((option) => {
                        if (option === "lining") return productDetail.lining; // Only show if true
                        if (option === "feeding Zipper")
                          return productDetail.feeding_zip;
                        if (option === "Sleeve Type")
                          return productDetail.sleeve_option;
                        if (option === "Length")
                          return productDetail.length_option;
                        return true; // Always show "Size"
                      })
                      .map((option, idx) => (
                        <div
                          className="filter__list widget_size hiddenCB"
                          key={idx}
                        >
                          <h3>Select {option}</h3>
                          <ul className="options-list">
                            {option === "Size" &&
                              ["XS", "S", "M", "L", "XL", "XXL", "XXXL"].map(
                                (size) => {
                                  // Check availability of each size from the productDetail object (e.g., XS_size, s_size, etc.)
                                  const sizeAvailable =
                                    productDetail[`${size.toLowerCase()}_size`]; // e.g., "XS_size", "s_size"

                                  return (
                                    <li key={size}>
                                      <input
                                        type="checkbox"
                                        id={`cb_${option}_${size}`}
                                        value={size}
                                        checked={selectedOptions.size === size}
                                        disabled={!sizeAvailable} // Disable the checkbox if the size is not available
                                        onChange={() => {
                                          setSelectedOptions((prev) => ({
                                            ...prev,
                                            size:
                                              prev.size === size ? "" : size, // Toggle selection
                                          }));
                                        }}
                                        style={{
                                          opacity: sizeAvailable ? 1 : 0.5, // Make it more transparent if the size is unavailable
                                          pointerEvents: sizeAvailable
                                            ? "auto"
                                            : "none", // Prevent interaction if size is unavailable
                                        }}
                                      />
                                      <label
                                        htmlFor={`cb_${option}_${size}`}
                                        style={{
                                          textDecoration: sizeAvailable
                                            ? "none"
                                            : "line-through", // Strike-through for unavailable sizes
                                          color: sizeAvailable
                                            ? "inherit"
                                            : "gray", // Change color to gray for unavailable sizes
                                        }}
                                      >
                                        {size === "XXXL"
                                          ? `${size} (+150 Rs)`
                                          : size}
                                      </label>
                                    </li>
                                  );
                                }
                              )}

                            {option === "feeding Zipper" &&
                              productDetail.feeding_zip &&
                              ["CENTER", "Twoside"].map((zipper) => (
                                <li key={zipper}>
                                  <input
                                    type="checkbox"
                                    id={`cb_${option}_${zipper}`}
                                    value={zipper}
                                    checked={selectedOptions.zipper === zipper}
                                    onChange={() => {
                                      setSelectedOptions((prev) => ({
                                        ...prev,
                                        zipper:
                                          prev.zipper === zipper ? "" : zipper, // Toggle selection
                                      }));
                                    }}
                                  />
                                  <label htmlFor={`cb_${option}_${zipper}`}>
                                    {zipper === "Twoside"
                                      ? `${zipper} (+100 Rs)`
                                      : zipper === "CENTER"
                                      ? `${zipper} (+50 Rs)`
                                      : zipper}
                                  </label>
                                </li>
                              ))}
                            {option === "Sleeve Type" &&
                              productDetail.sleeve_option &&
                              ["NormalElbowSleeve"].map((sleeve) => (
                                <li key={sleeve}>
                                  <input
                                    type="checkbox"
                                    id={`cb_${option}_${sleeve}`}
                                    value={sleeve}
                                    checked={selectedOptions.sleeve === sleeve}
                                    onChange={() => {
                                      setSelectedOptions((prev) => ({
                                        ...prev,
                                        sleeve:
                                          prev.sleeve === sleeve ? "" : sleeve, // Toggle selection
                                      }));
                                    }}
                                  />
                                  <label htmlFor={`cb_${option}_${sleeve}`}>
                                    {sleeve}
                                  </label>
                                </li>
                              ))}
                            {option === "lining" &&
                              productDetail.lining &&
                              ["Lining"].map((liningOption) => (
                                <li key={liningOption}>
                                  <input
                                    type="checkbox"
                                    id={`cb_${option}_${liningOption}`}
                                    value={liningOption}
                                    checked={
                                      selectedOptions.lining === liningOption
                                    }
                                    onChange={() => {
                                      setSelectedOptions((prev) => ({
                                        ...prev,
                                        lining:
                                          prev.lining === liningOption
                                            ? ""
                                            : liningOption,
                                      }));
                                    }}
                                  />
                                  <label
                                    htmlFor={`cb_${option}_${liningOption}`}
                                  >
                                    {liningOption === "Lining"
                                      ? `${liningOption} (+200 Rs)`
                                      : liningOption}
                                  </label>
                                </li>
                              ))}

                            {option === "Length" &&
                              productDetail.length_option &&
                              ["BELOW_47Inch", "ABOVE_47Inch"].map((length) => (
                                <li key={length}>
                                  <input
                                    type="checkbox"
                                    id={`cb_${option}_${length}`}
                                    value={length}
                                    checked={selectedOptions.length === length}
                                    onChange={() => {
                                      setSelectedOptions((prev) => {
                                        const newLength =
                                          prev.length === length ? "" : length;

                                        // Show input for both "ABOVE_47Inch" and "BELOW_47Inch"
                                        setShowLengthInput(
                                          newLength === "ABOVE_47Inch" ||
                                            newLength === "BELOW_47Inch"
                                        );

                                        return {
                                          ...prev,
                                          length: newLength,
                                        };
                                      });
                                    }}
                                  />
                                  <label htmlFor={`cb_${option}_${length}`}>
                                    {length === "ABOVE_47Inch"
                                      ? `${length} (+150 Rs)`
                                      : length}
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}
                    {showLengthInput && (
                      <div className="filter__list widget_size d-flex align-items-center">
                        <label htmlFor="length_inch">
                          <h3> Enter Length (in inches)</h3>
                        </label>
                        <input
                          type="number"
                          id="length_inch"
                          name="length_inch"
                          value={selectedOptions.length_inch || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedOptions((prev) => ({
                              ...prev,
                              length_inch: value,
                            }));
                          }}
                        />
                      </div>
                    )}
                    <div className="filter__list widget_size ">
                      <h3>Total Price </h3>
                      <p
                        className="options-list mt-3"
                        style={{ fontSize: "25px" }}
                      >
                        ₹
                        {calculateTotalPrice().toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                        })}{" "}
                        <span style={{ fontSize: "18px" }}>INR</span>
                      </p>
                    </div>

                    {/* Quantity Input */}

                    <div className="filter__list widget_size ">
                      <h3>Dispatch time </h3>
                      <p className="options-list mt-3">
                        {productDetail.dispatch_time}
                      </p>
                    </div>

                    <div className="filter__list widget_size ">
                      <h3>Add quantity</h3>
                      <p className="options-list mt-2">
                        {" "}
                        <div className="pro-qty d-flex align-items-center">
                          <button
                            className="button btn btn-primary"
                            type="button"
                            onClick={() =>
                              setQuantity(Math.max(1, quantity - 1))
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={(e) =>
                              setQuantity(Math.max(1, Number(e.target.value)))
                            }
                            className="qty-input text-center mx-1"
                            style={{ width: "50px", border: "none" }} // Adjust width as needed
                          />
                          <button
                            className="button btn btn-primary"
                            type="button"
                            onClick={() => setQuantity(quantity + 1)}
                          >
                            +
                          </button>
                        </div>
                      </p>
                    </div>

                    <div className="variant_quantity_btn mt-3">
                      <div className="">
                        <Link
                          className="wishlist"
                          to="#"
                          style={{ marginLeft: "10px" }}
                        >
                          <button
                            className="button btn btn-danger py-2 "
                            type="button"
                            onClick={() =>
                              handleAddToFavorites(productDetail.id)
                            }
                          >
                            <i className="ion-android-add"></i> Add to Favourite
                          </button>
                        </Link>
                        <button
                          className={`button btn py-2 ${
                            productDetail.sold_out
                              ? "btn-danger"
                              : "btn-success"
                          }`}
                          type="button"
                          onClick={
                            productDetail.sold_out ? null : handleAddToCart
                          } // Disable button if sold out
                          disabled={productDetail.sold_out} // Disable button if sold out
                        >
                          {productDetail.sold_out ? (
                            <span>Sold Out</span>
                          ) : (
                            <>
                              <i className="ion-android-add"></i> Add To Cart
                            </>
                          )}
                        </button>

                        {/* <button
                          className="button btn btn-success py-2"
                          type="button"
                          onClick={handleAddToCart}
                        >
                          <i className="ion-android-add"></i> Add To Cart
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </section>

      {modalIsOpen && (
        <div
          className="modal fade show"
          id="modal_box"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close mt-3"
                onClick={closeModal}
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  style={{
                    color: "red",
                    fontSize: "40px",
                    fontWeight: "900",
                  }}
                >
                  <i className="ion-android-close"></i>
                </span>
              </button>
              <div
                className="modal_body col-lg-12 col-md-12 col-sm-12 modal_zoom_gallery"
                style={{ position: "relative" }}
              >
                <div className="product_zoom_thumb" id="productselectedmodal">
                  <img
                    src={selectedProduct.images?.[currentImageIndex]?.image} // Use currentImageIndex
                    alt={selectedProduct.name}
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{ display: "block", margin: "0 auto" }} // Center the image
                  />
                </div>
                <div
                  className="image-navigation"
                  style={{
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <button
                    onClick={prevImage}
                    disabled={currentImageIndex === 0}
                    style={{
                      position: "absolute",
                      left: "10px",
                      zIndex: 1,
                      border: "1px solid black",
                      borderRadius: "50%",
                    }}
                  >
                    <HiArrowNarrowLeft />
                  </button>
                  <button
                    onClick={nextImage}
                    disabled={
                      currentImageIndex === selectedProduct.images?.length - 1
                    }
                    style={{
                      position: "absolute",
                      right: "10px",
                      zIndex: 1,
                      border: "1px solid black",
                      borderRadius: "50%",
                    }}
                  >
                    <HiArrowNarrowRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="product_d_info mb-118">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="product_d_inner">
                <div class="product_info_button border-bottom">
                  <ul class="nav" role="tablist">
                    <li>
                      <a
                        class="active"
                        data-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="true"
                      >
                        Product Description
                      </a>
                    </li>

                    <li>
                      <a
                        data-toggle="tab"
                        href="#tags"
                        role="tab"
                        aria-controls="tags"
                        aria-selected="false"
                      >
                        Product Detail
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#additional"
                        role="tab"
                        aria-controls="additional"
                        aria-selected="false"
                      >
                        Additional Information
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#shipping"
                        role="tab"
                        aria-controls="shipping"
                        aria-selected="false"
                      >
                        shipping & refund policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    id="info"
                    role="tabpanel"
                  >
                    <div class="product_info_content">
                      <p>
                        {productDetail
                          ? productDetail.description
                          : "Loading..."}
                      </p>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="tags" role="tabpanel">
                    <div class="product_info_content">
                      <ul>
                        <li>
                          Fabric:{" "}
                          {productDetail ? productDetail.fabric : "Loading..."}
                        </li>
                        <li>
                          Style:{" "}
                          {productDetail ? productDetail.style : "Loading..."}
                        </li>
                        <li>
                          Model Wearing Height:{" "}
                          {productDetail
                            ? productDetail.model_height
                            : "Loading..."}
                        </li>
                        <li>
                          Model Wearing Size:{" "}
                          {productDetail
                            ? productDetail.model_wearing_size
                            : "Loading..."}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="additional" role="tabpanel">
                    <div class="product_info_content">
                      <h3>About Our Brand</h3>
                      <p>
                        At <strong>Infiboutique</strong>, we believe in creating
                        timeless pieces that combine elegance with everyday
                        wearability. our mission is to empower individuals
                        through fashion that celebrates diversity and personal
                        style. Our collections are designed with meticulous
                        attention to detail, ensuring every item is not just
                        clothing, but a statement.
                      </p>

                      <h3>Materials & Craftsmanship</h3>
                      <ul>
                        <li>
                          <strong>Quality Fabrics:</strong> We source only the
                          finest materials, including organic cotton, luxurious
                          silk, and sustainable blends, ensuring comfort and
                          durability.
                        </li>
                        <li>
                          <strong>Ethical Production:</strong> All our products
                          are made ethically, with a focus on sustainability and
                          fair labor practices. We partner with skilled artisans
                          who share our commitment to quality and craftsmanship.
                        </li>
                      </ul>

                      <h3>Care Instructions</h3>
                      <ul>
                        <li>Hand wash in cold water or dry clean only.</li>
                        <li>Do not bleach.</li>
                        <li>Iron on low heat if necessary.</li>
                        <li>
                          Store in a cool, dry place away from direct sunlight
                          to maintain color vibrancy.
                        </li>
                      </ul>

                      <h3>Sizing Guide</h3>
                      <p>
                        To help you find the perfect fit, refer to our sizing
                        guide. We offer a range of sizes to accommodate various
                        body types, and our customer service team is available
                        to assist you with any questions regarding sizing or
                        fit.
                      </p>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="shipping" role="tabpanel">
                    <div class="product_info_content">
                      <h3>Return & Refund policy</h3>
                      <p>
                        <ul>
                          <li>
                            Kindly note that we do not facilitate return or
                            exchange requests, given that our products are
                            tailored to your specific order requirements.
                          </li>
                          <li>
                            However, if you receive a damaged product, we kindly
                            request you to contact us at
                            customercare.infi@gmail.com .
                          </li>
                          <li>
                            To assist you effectively, we ask that you provide
                            an unaltered video of the complete unboxing process.
                            Once we receive this, we will guide you through the
                            necessary steps for the exchange process.{" "}
                          </li>
                          <li>
                            To report any missing products we kindly request you
                            to contact us at customercare.infi@gmail.com . To
                            assist you effectively, we ask that you provide an
                            unaltered video of the complete unboxing process
                          </li>
                        </ul>
                      </p>

                      <h3>Refund policy</h3>
                      <ul>
                        <li>No Refund is available</li>
                      </ul>

                      <h3>Shipping policy</h3>
                      <ul>
                        <li>
                          Delivery time – we take about 10-15 business days to
                          carefully design and stitch a particular order.
                          (Business days do not include weekends and public
                          holidays)
                        </li>
                        <li>
                          Once the order is shipped it takes about 2 to 7
                          working days to be delivered within India.{" "}
                        </li>
                        <li>
                          Once order is placed please do not request for early
                          delivery as it’s not possible to prioritise any order.
                        </li>
                        <li>
                          We understand how important timely delivery is to you.
                          While we aren't responsible for any delays caused by
                          the courier company, we're always here to help You! If
                          you experience any issues, we'll gladly assist in
                          tracking your package through our partnering courier
                          service.
                        </li>
                        <li>
                          Please email if you have any queries regarding
                          existing orders.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showsizeModal && (
        <div className="modal-overlay-size" onClick={togglesizeModal}>
          <div
            className="modal-content-size"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-button-size" onClick={togglesizeModal}>
              &times;
            </span>
            <img
              src="../assets/img/sizechart.png" // Replace with your image path
              alt="Size Chart"
              className="size-chart-image"
            />
          </div>
        </div>
      )}
      <style jsx>{`
        .view-size-chart-button {
          margin-top: 10px;
          padding: 9px 12px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        .modal-overlay-size {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content-size {
          background: white;
          padding: 20px;
          border-radius: 8px;
          max-width: 500px;
          width: 100%;
          position: relative;
          text-align: center;
        }
        .close-button-size {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
        .size-chart-image {
          max-width: 100%;
          height: auto;
        }
      `}</style>
      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Related Products </h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {relatedProducts.map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              ₹
                              {discountedPrice.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                ₹
                                {originalPrice.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                })}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
