import React, { useState, useEffect } from "react";
import { fetchSearchResults } from "../Pages/Apiservice";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const SearchModal = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    if (!searchTerm) {
      setError("Please enter a product name.");
      return;
    }

    setError(""); // Clear any previous errors
    setLoading(true);
    try {
      const results = await fetchSearchResults(searchTerm);
      setSearchResults(results);

      if (results.length === 0) {
        setError("No products matched your search.");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("An error occurred while fetching results.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm("");
      setSearchResults([]);
      setError("");
    }
  }, [isOpen]);

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header" id="searchmodal">
            <h5 className="modal-title">Search Products</h5>
            <button type="button" className="closes" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex mb-3">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control me-2"
                placeholder="Search..."
              />
              <button onClick={handleSearch} className="btn btn-primary">
                Search
              </button>
            </div>

            {loading && <p>Loading...</p>}
            {error && <p className="text-danger">{error}</p>}

            <ul className="list-group">
              {searchResults.map((product) => (
                <li
                  key={product.id}
                  className="list-group-item d-flex align-items-center"
                  style={{ width: "100%" }}
                >
                  <Link to={`/Productdetail/${product.id}`}>
                    <div className="d-flex flex-row align-items-center w-100">
                      <img
                        src={product.images[0].image}
                        alt={product.name}
                        className="img-fluid me-2"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }} // Adjust size as needed
                      />
                      <div className="flex-grow-1">
                        {" "}
                        {/* This allows the text to take the remaining space */}
                        <span className="me-2">{product.name}</span>
                        <p className="mb-0">
                          $
                          {product.price != null
                            ? Number(product.price).toFixed(2)
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
