import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchorder, fetchtrack } from "./Apiservice";
import Swal from "sweetalert2";

const Trackorder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showsizeModal, setShowsizeModal] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const loadOrders = async () => {
      try {
        const data = await fetchorder(userId);
        console.log("Fetched orders:", data);

        if (data && Array.isArray(data.orders)) {
          const sortedOrders = data.orders.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setOrders(sortedOrders);
        } else {
          console.error("Fetched data is not valid:", data);
          setOrders([]);
        }
      } catch (error) {
        console.error("Failed to load orders", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to load orders. Please try again.",
        });
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, []);

  const handleTrackOrder = async (orderId) => {
    const userId = localStorage.getItem("userId");
    try {
      const data = await fetchtrack(userId, orderId);
      setTrackingDetails(data); // Set the specific order's tracking details
      setShowsizeModal(true);
    } catch (error) {
      console.error("Failed to load tracking details", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Tracking Details is not available",
      });
    }
  };
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = (e) => {
    e.preventDefault(); // Prevent default behavior of the link
    navigator.clipboard
      .writeText(trackingDetails.tracking_number) // Copy text to clipboard
      .then(() => {
        setCopySuccess(true); // Set success state to true if copied successfully
        setTimeout(() => {
          setCopySuccess(false); // Reset after 2 seconds
          // Redirect to the tracking URL after 3 seconds
          window.open(trackingDetails.tracking_url, "_blank");
        }, 1000);
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
        setCopySuccess(false); // Set success state to false if there's an error
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Function to open and close the modal
  const togglesizeModal = () => setShowsizeModal(!showsizeModal);

  const formatDate = (dateString) => {
    if (!dateString) return "Pending";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <h2 className="text-center my-5">Order List</h2>

      <div className="shopping_cart_area mt-5">
        <div className="container">
          <form action="#">
            <div className="cart_page_inner mb-60">
              <div className="row">
                <div className="col-12">
                  <div className="cart_page_tabel">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th className="text-center">Information</th>
                          <th>Product Detail</th>
                          <th>Price</th>
                          <th>Track</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) =>
                          order.items.length > 0 ? (
                            order.items.map((item) => (
                              <tr key={item.product_id} className="border-top">
                                <td>
                                  <div className="cart_product_thumb">
                                    <img
                                      src={
                                        item.images && item.images[0]
                                          ? item.images[0]
                                          : "placeholder.jpg"
                                      }
                                      alt={item.product_name}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="cart_product_text">
                                    <h4>{item.product_name}</h4>
                                    <ul>
                                      <li>
                                        Quantity: <span>{item.quantity}</span>
                                      </li>
                                      <li>
                                        Size:{" "}
                                        <span>
                                          {item.selected_size || "N/A"}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <div className="cart_product_remove text-center">
                                    <Link
                                      to={`/Productdetail/${item.product_id}`}
                                    >
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                      >
                                        View
                                      </button>
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div className="cart_product_price">
                                    <span>
                                      ₹
                                      {parseFloat(item.price).toLocaleString(
                                        "en-IN",
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="cart_product_remove text-center">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        handleTrackOrder(order.order_id)
                                      }
                                    >
                                      Track your order
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr key={order.order_id} className="border-top">
                              <td colSpan="5" className="text-center">
                                <div>
                                  <p>No items in this order.</p>
                                  <p>Order ID: {order.order_id}</p>
                                  <p>Order Amount: ₹{order.total_price}</p>
                                  {order.address && (
                                    <p>
                                      Address: {order.address.street},{" "}
                                      {order.address.city},{" "}
                                      {order.address.state},{" "}
                                      {order.address.postal_code},{" "}
                                      {order.address.country}
                                    </p>
                                  )}
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() =>
                                      handleTrackOrder(order.order_id)
                                    }
                                  >
                                    Track your order
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {showsizeModal && trackingDetails && (
        <div className="modal-overlay-size" onClick={togglesizeModal}>
          <div
            className="modal-content-size"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-button-size" onClick={togglesizeModal}>
              &times;
            </span>

            <div>
              <h2 style={{ textAlign: "center" }}>Track Your Order</h2>

              <div className="tracking-info">
                <p>
                  <strong>Tracking Number:</strong>
                  <a href="#" onClick={handleCopy}>
                    {trackingDetails.tracking_number}
                  </a>
                  {copySuccess && <span className="copy-success">Copied!</span>}
                </p>
                <p>
                  <strong>Check the Delivery partner:</strong>
                  <a href={trackingDetails.tracking_url} target="blank">
                    view website
                  </a>
                </p>
                <p>
                  <strong>Estimated Delivery:</strong>After three working days
                  from Shipping
                </p>
              </div>

              <hr className="tracking-line" />
              <div className="trackbox">
                <ul className="progressList">
                  <li className="progressList_item">
                    <p
                      className={`progressList_content ${
                        trackingDetails.order_received ? "green" : "green"
                      }`}
                      id="orderstatic"
                    >
                      Order Received
                      <small>
                        {formatDate(trackingDetails.created_at) || "N/A"}
                      </small>
                    </p>
                  </li>

                  <li
                    className={`progressList_item ${
                      trackingDetails.shipped_date ? "is_complete" : ""
                    }`}
                  >
                    <p
                      className={`progressList_content ${
                        trackingDetails.shipped_date ? "green" : "gray"
                      }`}
                    >
                      Order Shipped
                      <small>
                        {formatDate(trackingDetails.shipped_date) || "N/A"}
                      </small>
                    </p>
                  </li>

                  <li
                    className={`progressList_item ${
                      trackingDetails.in_transit ? "in_progress" : ""
                    }`}
                  >
                    <p
                      className={`progressList_content ${
                        trackingDetails.delivered_date
                          ? "green"
                          : trackingDetails.in_transit
                          ? "orange"
                          : "gray"
                      }`}
                    >
                      In Transit
                      <small>
                        {trackingDetails.delivered_date
                          ? formatDate(trackingDetails.delivered_date)
                          : "Pending"}
                      </small>
                    </p>
                  </li>

                  <li
                    className={`progressList_item ${
                      trackingDetails.delivered_date ? "is_complete" : "pending"
                    }`}
                  >
                    <p
                      className={`progressList_content ${
                        trackingDetails.delivered_date ? "green" : "gray"
                      }`}
                    >
                      Delivered
                      <small>
                        {formatDate(trackingDetails.delivered_date) ||
                          "Pending"}
                      </small>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal-overlay-size {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .modal-content-size {
          background: white;
          padding: 20px;
          border-radius: 10px;
          max-width: 600px;
          width: 100%;
          position: relative;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }

        .close-button-size {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }

        .tracking-info p {
          font-size: 16px;
          margin: 10px 0;
          color: #555;
        }
        .trackbox{
          display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

        }

        .tracking-line {
          border: none;
          height: 1px;
          background: #ddd;
          margin: 20px 0;
        }

        .progressList {
          list-style-type: none;
          padding: 0;
          margin: 0;
          position: relative;
        }

        .progressList_item {
          display: flex;
          align-items: center;
          padding: 15px 0;
          position: relative;
        }

    /* Default Gray Color */
.progressList_item .progressList_content {
  position: relative;
  font-size: 14px;
  padding-left: 25px;
  display: inline-block;  /* Ensure content stays inline */
  white-space: nowrap;    /* Prevent content from wrapping */
  vertical-align: middle; /* Align text and small tag */
}

.progressList_item .progressList_content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #9e9e9e; /* Default color (gray) */
  transform: translateY(-50%);
}

#orderstatic {
  color: #4caf50; /* Green text */
}

/* Green for Order Shipped confirmed */
.progressList_item .progressList_content.green {
  color: #4caf50; /* Green text */
}

.progressList_item .progressList_content.green::before {
  background-color: #4caf50; /* Green circle */
}

/* Gray for Order Shipped if not confirmed */
.progressList_item .progressList_content.gray {
  color: #ff9800; /* Gray text */
}

.progressList_item .progressList_content.gray::before {
  background-color: #ff9800; /* Orange circle */
}

/* Orange for In Transit if not delivered */
.progressList_item .progressList_content.orange {
  color: #ff9800; /* Orange text */
}

.progressList_item .progressList_content.orange::before {
  background-color: #ff9800; /* Orange circle */
}

/* Green for In Transit when delivered */
.progressList_item .progressList_content.green {
  color: #4caf50; /* Green text */
}

.progressList_item .progressList_content.green::before {
  background-color: #4caf50; /* Green circle */
}

/* Pending status (gray for undelivered) */
.progressList_item.pending .progressList_content {
  color: #9e9e9e; /* Gray text */
}

.progressList_item.pending .progressList_content::before {
  background-color: #9e9e9e; /* Gray circle */
}


        .progressList_item .progressList_content small {
          display: block;
          font-size: 14px;
          padding-left: 10px;
          color: Blue;
        }

        @media (max-width: 600px) {
          .modal-content-size {
            padding: 15px;
          }

          .close-button-size {
            top: 8px;
            right: 8px;
            font-size: 22px;
          }

          .tracking-info p {
            font-size: 14px;
          }

          .progressList_content {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default Trackorder;
